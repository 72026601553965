import {
    MENU_DATA_SUCCESS,
    MENU_DATA_START,
    CURRENCY_VALUE,
    SEARCH_CATEGORY,
    GLOBAL_SEARCH_TEXT,
    SET_GLOBAL_SEARCH_RESULT,
    HIDE_GLOBAL_SEARCH_CONTAINER
} from "../actions/Actions";

const initialState = {
    isFetching: false,
    menuData: {},
    currency: "EUR",
    searchCategory: {},
    globalSearchResult: [],
    showGlobalSearchContainer: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MENU_DATA_START:
            return Object.assign({}, {
                ...state,
                isFetching: true,
            });
        case MENU_DATA_SUCCESS:
            return Object.assign({}, {
                ...state,
                isFetching: false,
                menu: action.payload.menu,
            });
        case CURRENCY_VALUE:
            return Object.assign({}, {
                ...state,
                currency: action.payload.currency,
            });
        case SEARCH_CATEGORY:

            return Object.assign({}, {
                ...state,
                searchCategory: action.payload.searchCategory,
            });
        case GLOBAL_SEARCH_TEXT:

            return Object.assign({}, {
                ...state,
                searchText: action.payload,
                showGlobalSearchContainer: true
            });
        case SET_GLOBAL_SEARCH_RESULT:

            return Object.assign({}, {
                ...state,
                globalSearchResult: action.payload,
                showGlobalSearchContainer: true
            });
        case HIDE_GLOBAL_SEARCH_CONTAINER:

            return Object.assign({}, {
                ...state,
                showGlobalSearchContainer: false
            });
            
        default:
            return state;
    }
}
