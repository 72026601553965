import React, { Component } from "react";
import Header from "../../header";
import Categories from "../widgets/categories"
//import HotDeals from "../widgets/hot-deals"
//import Tags from "../widgets/tags"
//import SpecialDeal from "../widgets/special-deal"
// import NewsLatter from "../widgets/newslatter"
// import Testimonials from "../widgets/testimonials"
import Slider from "../widgets/slider"
import NewProducts from "../widgets/new-products"
// import FeaturedProducts from "../widgets/featured-products"
//import Banner from "../widgets/banner"
//import BestSeller from "../widgets/best-seller"
//import LatestFromBlog from "../widgets/latest-from-blog"
//import NewArrivals from "../widgets/new-arrivals"
//import BrandsCarousel from "../widgets/brands-carousel"
import Footer from "../../footer"

import "./styles.scss"
export default class HomePage extends Component {
  render() {
    return (
      <div className="cnt-home">
        <Header />
        <div className="body-content outer-top-xs" id="top-banner-and-menu">
          <div className="container home-page">
          <Slider />
          <Categories />
            <div className="row">
            
            
              {/* <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
               <Categories />
                <HotDeals />
                <Tags />
                <SpecialDeal />
                <NewsLatter />
                <Testimonials /> 
              </div>*/}
              <div className="col-xs-12 col-sm-12 col-md-12 homebanner-holder">
                {/* <Slider /> */}
                <NewProducts />
                {/* <FeaturedProducts /> */}
                {/* <Banner />
                <BestSeller /> */}
                {/* <LatestFromBlog /> */}
                {/* <NewArrivals /> */}
              </div>
            </div>
            {/* <BrandsCarousel /> */}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
