import { USD_VALUE, MDL_VALUE } from "../../constants"

export const isValidEmail = (email) => {
    const regExp = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
}

export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

export const showCurrencyPrice = (finalPrice, inCurrency) => {
  let price
  switch (inCurrency) {
    case "MDL":
      price = (finalPrice * MDL_VALUE).toFixed(2)
      break;
    case "USD":
      price = (finalPrice * USD_VALUE).toFixed(2)
      break;

    default:
      price = finalPrice
      break;
  }
  return price
}